.container{
    padding: 10px;
    width: 100%;
}

.otpContainer{
    display: flex;
    width: 340px;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
    position: relative;
    font-size: 16px;
}
.otpButton{
    color: #f26e5f;
    cursor: pointer;
}
.otpResendContainer{
    display: flex;
    gap: 5px;
    font-size: 14px;
    margin: 5px 0 15px 0;
}
.resendButton{
    color: #f26e5f;
    cursor: pointer;
}

.otpVerifyModule{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 680px){
    .container{
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 320px){
    .otpContainer{
        width: 260px;
    }
}