.dialogContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1rem;
  gap: 1rem;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  @media (max-width: 400px) {
    flex-direction: column;
    gap: 0.5rem;
  }
}

.icon {
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}
