.header {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  text-align: left;
  padding: 0.5rem;

  .title {
    color: #d01a2a;
    margin-left: 0.5rem;
    text-transform: none;
    font-weight: bold;
  }
}

.accordion {
  margin-top: 0;

  .container {
    max-height: 345px;
    overflow-y: auto;
    padding-left: 1rem;
    margin-top: 0;
    text-align: left;
  }
}

