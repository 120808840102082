.sizeChartContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-bottom : 10px;
}

.sizeChartContainer * {
    font-size: 14px;
}

.sizeChartContainer span {
    color: #494949;
}

.linkBtn {
    border: none;
    outline: none;
    background: none;
    border-bottom: 1px solid #266E6A;
    color: #266E6A;
    font-size: 12px;
}

.termsContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-bottom: 20px;
    gap: 10px;
}

.terms {
	color: #525252;
	font-size: 12px;
	word-wrap: break-word;
	width: 275px;
}