ul {
  list-style: none;
  padding-left: 0;
}

hr {
  height: 5px;
  border-width: 0;
  background-color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}
