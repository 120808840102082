.test {
  background-color: red;
}

.containerHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.containerBody {
  max-height: 345px;
  overflow-y: auto;
}
